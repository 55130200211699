<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md3>
      <v-card class="elevation-12">
        <v-toolbar color="primary">
          <v-toolbar-title>Autentique-se</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form>
            <v-text-field
              prepend-icon="mdi-account"
              name="email"
              type="text"
              placeholder="E-Mail"
              solo
              v-model="form.email"
              :disabled="loading"
            ></v-text-field>
            <v-text-field
              id="password"
              prepend-icon="mdi-lock"
              name="password"
              type="password"
              placeholder="Senha"
              solo
              v-model="form.password"
              :disabled="loading"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <div class="text-right pa-2">
          <v-btn
            text
            small
            :color="this.$vuetify.theme.themes.light.info"
            @click.stop="passwordRecoveryDialog = true"
            >Esqueci minha senha</v-btn
          >
        </div>

        <div class="pa-4">
          <v-btn
            block
            color="black white--text mb-3"
            class="pa-6"
            @click="signin()"
            :loading="loading"
            >Entrar</v-btn
          >
        </div>
      </v-card>
    </v-flex>

    <v-dialog v-model="passwordRecoveryDialog" width="500">
      <v-card>
        <v-card-title class="primary"> Recuperar senha </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="recoveryEmail"
            label="E-mail"
            placeholder="Informe o seu e-mail de cadastro"
            hint="Instruções de como recuperar sua senha serão enviadas no seu e-mail de cadastro"
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="passwordRecoveryDialog = false"> Cancelar </v-btn>
          <v-btn color="primary" @click="recoveryPassword()"> Enviar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="successRecoveryPasswordDialog" max-width="400">
      <v-card>
        <v-card-title> Sucesso! </v-card-title>
        <v-card-text> Enviamos instruções para o seu e-mail. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click.stop="successRecoveryPasswordDialog = false"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapActions } from "vuex";
import errorHandler from "@/helpers/error_handler";

export default {
  data: () => ({
    passwordRecoveryDialog: false,
    successRecoveryPasswordDialog: false,
    loading: false,
    recoveryEmail: null,
    form: {},
  }),
  methods: {
    ...mapActions(["user/signin"]),
    validateForm() {
      const errors = [];

      if (!this.form.email) errors.push("E-mail de acesso não informado");

      if (!this.form.password) errors.push("Senha de acesso não informada");

      return errors;
    },
    async signin() {
      try {
        const errors = this.validateForm();
        if (errors.length > 0) return this.$root.$errorDialog(errors);
        this.loading = true;
        await this["user/signin"](this.form);
        this.$router.push("/clientes");
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);
        this.$root.$errorDialog(errorHandled);
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async recoveryPassword() {
      try {
        this.loading = true;

        await this.$axios.post("/users/recoverypassword", {
          email: this.recoveryEmail,
        });

        this.passwordRecoveryDialog = false;
        this.recoveryEmail = null;
        this.successRecoveryPasswordDialog = true;
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);
        this.$root.$errorDialog(errorHandled);
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
